<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
				<el-form-item label="流水号:">
				  <el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable
						></el-input>
				</el-form-item>
        <el-form-item label="发布人:">
          <el-input 
						v-model.trim="searchForm.pubusername" 
						@keyup.enter.native="seach"
						clearable></el-input>
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable></el-input>
        </el-form-item>
				<el-form-item label="审核状态:">
				  <el-select 
						v-model="searchForm.approveflag" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="projecttyperef"
						placeholder="请选择" 
						clearable>
				    <el-option value="-2" label="未提请审批" />
				    <el-option value="-1" label="审核中" />
				    <el-option value="0" label="审核不通过" />
				    <el-option value="1" label="通过" />
				  </el-select>
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>

      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
			<el-table-column label="流水号" prop="id"></el-table-column>
      <el-table-column label="主题" prop="title" align="center">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.title
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="发布人"
        prop="pubusername"
        align="center"
      ></el-table-column>
      <el-table-column
        label="类别"
        prop="type"
        align="center"
      ></el-table-column>
      <el-table-column
        label="一级类别"
        prop="type1"
        align="center"
      ></el-table-column>
      <el-table-column label="发布时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
			<el-table-column
			  label="已阅读人数"
			  prop="readcount"
			  align="center"
			></el-table-column>
			<el-table-column
			  label="未阅读人数"
			  prop="noreadcount"
			  align="center"
			></el-table-column>
			<el-table-column
			  label="审核标识"
			  prop="approveflag"
			  align="center"
			  width="120"
			>
			  <template slot-scope="scope">
			    {{ approveflag[scope.row.approveflag] }}
			  </template>
			</el-table-column>
			<el-table-column
			  label="审核人"
			  prop="approveusername"
			  align="center"
			></el-table-column>
      <el-table-column
        label="所属教学点"
        prop="schoolorgname"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" class-name="btn-2b5a95">
        <template slot-scope="scope">
          <el-button type="primary" @click="downloadFile(scope.row)"
            >下载附件</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="50%"
      @close="onCloseAdd"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题:" prop="title">
          <el-input v-model.trim="dynamicValidateForm.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="类别:" prop="typekey">
          <el-select
            v-model="dynamicValidateForm.typekey"
            placeholder="请选择"
            clearable
            @change="onChangeBulletinType"
          >
            <el-option
              v-for="item in bulletinType"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="一级类别:" prop="type1">
          <el-select
            v-model="dynamicValidateForm.type1"
            placeholder="请选择"
            clearable
            v-if="dynamicValidateForm.type !== '2'"
          >
            <el-option
              v-for="item in bulletinType1"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>

          <el-input v-model.trim="dynamicValidateForm.type1"  clearable v-else></el-input>
        </el-form-item>
        <el-form-item label="文件号:" prop="filecode">
          <el-input v-model.trim="dynamicValidateForm.filecode" clearable></el-input>
        </el-form-item>
        <el-form-item label="签发人:" prop="issuername">
          <el-input v-model.trim="dynamicValidateForm.issuername" clearable></el-input>
        </el-form-item>
        <el-form-item
          label="审核人:"
          prop="approveusername"
        >
          <el-input v-model="dynamicValidateForm.approveusername" disabled></el-input>
          <el-button type="primary" class="select-btn"  @click="
              onSelectData(
								user_dialoguerrsult6,
                'auditorAddModal',
                null
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="内容:" prop="content">
          <div id="complaint_editor" ref="editor" style="width:90%"></div>
        </el-form-item>
        <el-form-item label="附件：">
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :file-list="fileList"
            :on-remove="onRemoveAttatchurl"
          >
            <el-button size="small" type="primary" class="select-btn"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item 
          label="发送组:" 
          >
				  <el-select 
            v-model="dynamicValidateForm.discountfee" 
            placeholder="请选择" 
            @change="onCountfee"
            >
				     <el-option v-for="item in map"
				        :key="item.id"
				        :label="item.name"
				        :value="{value: item.id, label: item.name}"
				     ></el-option>
				   </el-select>
        </el-form-item>
        <el-form-item
          label="发送范围:"
          prop="usernames"
        >
          <el-input 
            v-model="dynamicValidateForm.usernames" 
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn"  @click="
              onSelectData(
								user_dialoguerrsult6,
                'addShowAddModal',
                null
              )
            "
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择审核人："
      :visible.sync="auditorAddModal"
      v-if="auditorAddModal"
      append-to-body
    >
			<el-button type="primary" @click="onauditename">确定</el-button>
     <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="成员:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
						 @click="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				border
				:key="key"
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="成员" prop="realname" align="center" />
				<el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
      <el-pagination
				background
				@current-change="
				 (val, data) =>
				   handleSelectChange(val, Object.assign({}, selectSearchForm))
				"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 发送范围 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择成员："
      :visible.sync="addShowAddModal"
      v-if="addShowAddModal"
      append-to-body
    >
			<el-button type="primary" @click="onConfirmCollegename">确定</el-button>
     <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="成员:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
						 @click="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				border
				:key="key"
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="成员" prop="realname" align="center" />
				 <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
      <el-pagination
				background
				@current-change="
				 (val, data) =>
				   handleSelectChange(val, Object.assign({}, selectSearchForm))
				"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
			<div class="global abase">
			  <div class="top title_deli" style="color: #606266;font-size: 20px;">
			    {{ dynamicValidateForm.title }}
			  </div>
			  <p class="content_list padding-15" v-html="dynamicValidateForm.content">
			  </p>
			</div>
    </el-dialog>

     <!-- 负责人审批 -->
     <el-dialog
        title="人员审批："
        :visible.sync="showApprovalModal"
        v-if="showApprovalModal"
        append-to-body
        width="56%"
    >
      <el-form
        :model="headApprovalForm"
        ref="headApprovalForm"
        class="demo-dynamic"
        status-icon
        label-width="35%"
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="headApprovalForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 0, label: '不通过' },
                { value: 1, label: '通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model="headApprovalForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval('headApprovalForm')">提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import E from "wangeditor";
import { mapActions } from "vuex";
import { part } from "@/utils/mixins";
import {
  bulletin_list,
  bulletin_input,
  bulletin_save,
  bulletin_delete,
  usergroup_list2,
  usergroup_getuser,
  user_dialoguerrsult6,
  bulletin_approvecheck,
  bulletin_approvesave,
} from "@/request/api/allChildrenProject";
import { _fileupload_save, _filedownload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _approveflag,
} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "bulletin",
  components: {},
  props: {
    user_dialoguerrsult6: {
      default: () => user_dialoguerrsult6
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: { id: null },
      tableRes: {}, //列表接口
      editor: null,//富文本
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      map:"",
      usernames: [],
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				typekey: [{ trigger: "blur", message: "必填字段", required: true }],
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        type1: [{ trigger: "blur", message: "必填字段", required: true }],
        usernames: [{ trigger: "blur", message: "必填字段", required: true }],
        attatchurl: [{ trigger: "blur", message: "必填字段", required: true }],
        filecode:[{ trigger: "blur", message: "必填字段", required: true }],
        issuername: [{ trigger: "blur", message: "必填字段", required: true }],
        content: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),
			key: '',
      headApprovalForm: {},
      showSendrangeModal: false,
			approveflag: _approveflag,
      bulletinType: [], //类别
      bulletinType1: [], //一级类别
      showDetailModal: false,
      fileList: [],
      addShowAddModal: false,
      //发送范围
      attatchurlData: [],
      defaultProps: {
        label: "name",
        children: "userlist",
      },
      defaultCheckMenu: [],
      currentCheckMenu: [],
      showAttatchurl: false,
      auditorAddModal: false,
      showApprovalModal: false,
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      selectTableUrl: "",
    };
  },
  created() {
    this.searchForm.id = this.$route.params.id;
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.getDataList();
    window.addEventListener("keyup", this.enterSelect);
  },
  mounted() {
    this.dic_combox({
      typecode: "bulletintype",
      list: "bulletinType",
      that: this,
    });
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "org_combox",
      "org_comboxchild",
      "dic_combox",
    ]),
    enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
    //点击tree复选框 click
    onCheckAuth(current, data) {
      this.currentCheckMenu = data.checkedNodes;
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //改变类别 change
    onChangeBulletinType(val) {
      if (!val) return (this.bulletinType1 = []);
      const item = this.bulletinType.find((i) => i.key === val);
      if (item) {
        this.dynamicValidateForm.type = item.val;
      }
      this.dic_combox({
        typecode: "bulletintype" + val,
        list: "bulletinType1",
        that: this,
      });
    },
    //初始化富文本编辑器 event
    initEditor() {
      const that = this;
      this.$nextTick(() => {
        if (this.editor) {
          return this.editor.txt.html(this.dynamicValidateForm.content);
        }
        this.editor = new E("#complaint_editor");
        this.editor.config.withCredentials = true;
        this.editor.config.uploadFileName = "upfile";
        this.editor.config.customUploadImg = function (
          resultFiles,
          insertImgFn
        ) {
          that.fds.append("upfile", resultFiles[0]); // 传文件
          myRequest({
            method: "post",
            url: _fileupload_save,
            data: that.fds,
          }).then((res) => {
            if (res.data.code === "200") {
              that.fds = new FormData();
              // 上传图片，返回结果，将图片插入到编辑器中
              insertImgFn(that.$url.upload + res.data.url);
            }
          });
        };
        this.editor.config.onchange = (html) => {
          this.dynamicValidateForm.content = html;
        };
        this.editor.create();
        this.editor.txt.html(this.dynamicValidateForm.content);
			
      });
    },
    //关闭添加，修改框 click
    onCloseAdd(){
      this.editor = null
    },
    onCountfee(data) {
			 const { value, label } = data
			  this.dynamicValidateForm.parentid = value
			  this.dynamicValidateForm.username = label
      this.getuser();
		},
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		reset() {
			this.searchForm = {
				id: null,
				pubusername: null,
				schoolorgname: null,
        approveflag: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: bulletin_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    // 发送组
    usergroupList() {
      myRequest({
        url: usergroup_list2,
      }).then((res) => {
        if (res.data.code === "200") {
          this.map = res.data.data.list;
					for(const key in this.map){
						// console.log(key,'key')
						// console.log('value',this.map[key])
					}
        }
      });
    },
    getuser() {
      myRequest({
        url: usergroup_getuser,
        data: {
          parentid: this.dynamicValidateForm.parentid
        }
      }).then((res) => {
        if (res.data.code === "200") {
            this.$set( 
						  this.dynamicValidateForm,
						  "usernames",
						  res.data.data.list.map((i) => i.name).join(',')
						);
          this.$set(
						  this.dynamicValidateForm,
						  "userid",
						  res.data.data.list.map((i) => i.userid).join(',')
						);
        }
      });
    },    
     //确认院校 click
    onConfirmCollegename() {
      selectCheck(this.multipleSelection, "选择", true, () => {
        this.dynamicValidateForm.useid= this.$set(
            this.dynamicValidateForm,
            "useid",
            this.multipleSelection.map((i) => i.id)
          );
         this.dynamicValidateForm.username = this.$set(
            this.dynamicValidateForm,
          "username",
          this.multipleSelection.map((i) => i.realname)
        );
				this.uid = this.dynamicValidateForm.useid.concat(this.dynamicValidateForm.userid)
				this.names = this.dynamicValidateForm.username.concat(this.dynamicValidateForm.usernames)
				this.filterNames = this.names.filter(Boolean); 
				this.filterUid = this.uid.filter(Boolean);
				this.dynamicValidateForm.usernames = this.filterNames.join(",");
				this.dynamicValidateForm.userid = this.filterUid.join(",");
        this.addShowAddModal = false;
				this.multipleSelection = [];
      });
    },
     //确认审核人 click
     onauditename() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "approveuserid",
          this.multipleSelection.map((i) => i.id).join(",")
        );
        this.$set(
          this.dynamicValidateForm,
          "approveusername",
          this.multipleSelection.map((i) => i.realname).join(",")
        );
				this.auditorAddModal = false;
				this.multipleSelection = [];
      });
    },
      //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
     //审批 click
     btnVeryfy1() {
      selectCheck(this.multipleSelection, "审批", false, () => {
        this.approvecheck();
      });
    },
		//检查是否可以审批 api
		approvecheck() {
		  myRequest({
		    method: "post",
		    url: bulletin_approvecheck,
		    data: this.$qs.stringify(
		      {
		        ids: this.multipleSelection.map((i) => i.id),
		      },
		      {
		        arrayFormat: "repeat",
		      }
		    ),
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.headApprovalForm = {};
		      this.showApprovalModal = true;
		    }
		  });
		},
		//负责人审批 api
		headApproval(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      myRequest(
		        {
		          method: "post",
		          url: bulletin_approvesave,
		          data: this.$qs.stringify(
		            {
		              ids: this.multipleSelection.map((i) => i.id),
		              passflag: this.headApprovalForm.passflag,
		              approvedesc: this.headApprovalForm.approvedesc,
		            },
		            {
		              arrayFormat: "repeat",
		            }
		          ),
		        },
		        {
		          isRefTableList: true,
		          that: this,
		
		          modal: "showApprovalModal",
		        }
		      );
		    }
		  });
		},


    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.fileList = [];
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.usergroupList();
      if (this.editor) {
        this.editor.txt.html("");
      }
      this.showAddModal = true;
      this.initEditor();
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //初始化修改 event
    initEdit() {
      this.$set(this.dynamicValidateForm, "typekey", this.dynamicValidateForm.type);
    },

    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //通知内容文件删除 remove
    onRemoveAttatchurl(file, fileList) {
      if (!fileList.length) {
        this.dynamicValidateForm.attatchurl = "";
      }
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.$set(this.dynamicValidateForm, "attatchurl", res.data.url);
        }
      });
    },
    //下载 click
    downloadFile(row) {
      location.href =
        this.$url.Freemarker +
        _filedownload_save +
        "?fileurl=" +
        row.attatchurl;
    },
    //选择发送范围 click
    onSendRangeClick() {
      this.showSendrangeModal = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: bulletin_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vbulletin;
					this.dynamicValidateForm.usernames = res.data.data.usernames.toString();
					this.dynamicValidateFormuserids = res.data.data.userids.toString();
          this.initEdit();
					this.initEditor();
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.dynamicValidateForm.useid;
          delete this.dynamicValidateForm.username;
          myRequest(
            {
              method: "post",
              url: bulletin_save,
              data: this.$qs.stringify(this.dynamicValidateForm, {
                arrayFormat: "repeat",
              }),
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: bulletin_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    selectQuery() {
			this.selectSearchForm = {
        realname: null,
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(user_dialoguerrsult6, null, null);
		},
     //选项表格 api
     onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
   
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    
  },
  beforeDestroy() {
		this.editor = null;
	}
};
</script>

<style  lang="scss">
	 .base_table{
	  margin: auto;
	 }
</style>